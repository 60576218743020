var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.episodes,"search":_vm.search,"single-expand":_vm.singleExpand,"group-by":"series","expanded":_vm.expanded,"item-key":"id","show-expand":"","show-group-by":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.published_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.published_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length},domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openPage(item.slug)}}},[_vm._v(" mdi-arrow-top-right ")])]}}])},[_vm._v("` ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }