<template>
   <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="episodes"
      :search="search"
      :single-expand="singleExpand"
      group-by="series"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      show-group-by
    >
      <template v-slot:item.published_at="{ item }">
        {{ item.published_at | moment}}
      </template>`
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" v-html="item.description">
        </td>
      </template>
      <template v-slot:item.slug="{ item }">
        <v-icon
        small
        @click="openPage(item.slug)"
      >
        mdi-arrow-top-right
      </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
 import axios from 'axios'
 import moment from 'moment'
 export default {
    data () {
      return {
        search: '',
        singleExpand: true,
        expanded: [],
        headers: [
          {
            text: 'Episode Num',
            align: 'start',
            value: 'episode',
            groupable: false,
          },
          { text: 'Series', value: 'series' },
          { text: 'Title', value: 'title', groupable: false, },
          // { text: 'Published', value: 'published'},
          { text: 'Publish Date', value: 'published_at', groupable: false, },
          { text: '', value: 'data-table-expand', groupable: false, },
          { text: '', value: 'slug', groupable: false, },
        ],
        episodes: [],
      }
    },
    filters: {
     moment: function (date) {
       return moment(date).format('DD MMM, yyyy');
     },
     toHHMMSS: function (duration) {
       if (duration.includes(":")) {
         //already formatted
         return duration;
       } else {
         let seconds = duration % 60
         return parseInt(duration / 60) + ":" + ('0'+seconds).slice(-2);
       }
     },
    },
    methods: {
      openPage: function(slug) {
        window.open("https://lavaforgood.com/podcast/" + slug + "/")
      }

    },
    mounted () {
      axios
        .get('/api/v1/admin/episodes')
        .then((response) => {
         console.log(response)
         this.episodes = response.data.episodes
       })
       .catch((error) => {
         console.log(error)
       })
    }
  }
</script>
